import { Button, Chip, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import './home_bg.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
export default function Home_bg() {


  useEffect(() => {
    AOS.init();

  }, [])
  
  return (
<div className="pickgradient">

<img src={require('../../images/home_bg.jpg')} className='home_bg' />

<Grid container className='home_bg_maincont'>
<Grid container sm={9} xs={12}>
<Grid item sm={12} xs={12}>
<Typography data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1000" className='home_bg_head_S'>
Collaborate. Connect.

</Typography>

</Grid>

<Grid item sm={12} xs={12}>
<Typography data-aos="zoom-in-up"  data-aos-easing="linear" data-aos-duration="1000"  className='home_bg_head'>
<span style={{color:'var(--color3)'}}>Innovate -</span> Affiliate Marketing
</Typography>

</Grid>
<Grid item style={{maxWidth:'90%'}} sm={12} xs={12}>
<Typography className='home_bg_text'>
Affiliate marketing is a performance-based marketing strategy where individuals or businesses earn commissions by promoting other people's products or services.
</Typography>

</Grid>
<Grid container sm={12} xs={12}>
<Grid item >
<Chip onClick={()=>window.location.href='/contact'} label='Explore now' style={{cursor:'pointer',background:'var(--color3)',color:'white',padding:'20px 10px',textTransform:'uppercase',fontWeight:'700'}} />
</Grid>


</Grid>

</Grid>

</Grid>



    </div>
  )
}
