import { Grid ,Container} from '@mui/material'
import React from 'react'
import './footer.css'
import { Link, NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="Footer_Items_Block">
        <Container className="Footer_Items">
       
       
       <Grid container>
        <Grid  item sm={12} xs={12}>
        <div style={{padding:10,margin:0}}>
                        &#169;2024 PerformCo Media
                    </div>
            </Grid> 
            <Grid  item sm={12} xs={12}>
    

            <div className="Footer_Items_NavLink Footer_Items_NavLink1">
                <NavLink className="Footer_Link" exact to="/privacy-policy">
                        Privacy policy
                </NavLink>
            </div>
            <div className="Footer_Items_NavLink Footer_Items_NavLink2">
            <NavLink className="" to="/terms-and-conditions" target='_self'>
                            Terms & Conditions
                        </NavLink>
            </div>
            </Grid>
            </Grid>
            {/* <div className="Footer_Items_NavLink Footer_Items_NavLink3">
                           <a className="" href="mailto:contact@thehealthgossip.com" target='_self'>
                            Contact us
                        </a>
            </div> */}
            {/* <div className="Footer_Items_P_Div">  
                <p className="Footer_Items_P">
                    &#169;2023 Dec6 Consulting Private Limited

 
                </p>
            </div> */}
        </Container>
    </div>
    )
}

export default Footer
