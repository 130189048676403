import React, { useState } from 'react';
import {NavLink} from "react-router-dom";

import Grid from '@mui/material/Grid';
import './header.css'
import { Typography } from '@mui/material';
// import { useLogoutUserMutation } from '../../../slices/usersApiSlice';

function Navbar() {
  const [navbarA,setNavbar] = useState(false);
// const [logoutUser,{isLoading}]=useLogoutUserMutation()
  const changeBackground = () => {
    if(window.scrollY >= 100)
    {
        setNavbar(true);
    }
    else {
        setNavbar(false);
    }
};
window.addEventListener('scroll',changeBackground);
      


return(
            <nav>
            <div style={{width:'100%',justifyItems:'center',display:'grid'}}>
            <div className={navbarA?"Navbar_ItemsA":'Navbar_Items'}>

<Grid justifyContent={'flex-start'} display={'flex'} alignItems={'center'} container style={{paddingRight:'4rem'}}  className='gird_nav'>

<Grid container sm={3} xs={3}>
{/* <div style={{height:50,width:50,borderRadius:'50%',backgroundColor:'white',marginLeft:'2vw'}}> */}
<img   src={require('../../images/Image.jpg')} onClick={()=>window.location.href='/'}  style={{height:60,marginLeft:'2vw',cursor:'pointer'}} ></img>

{/* </div> */}
</Grid>
<Grid container justifyContent={'flex-end'} sm={9} xs={9}>


<Grid item>
<NavLink className={({ isActive }) => isActive ? "Navbar_Link2" : "Navbar_Link1"}  to="/contact"   >
                              CONTACT US
                            </NavLink>
</Grid>
<Grid item>
<Typography className={"Navbar_Link1"}  onClick={()=>window.open("https://performco.trackier.io/","_blank")}   >
                              PUBLISHER LOGIN
                            </Typography>
</Grid>



    </Grid>   
 
    </Grid>               
            </div>


            </div>





            </nav>
        );
    }
    
    export default Navbar;