import React from 'react'
import './below_bg.css'
import { Chip, Container, Grid, Typography } from '@mui/material'
export default function Below_bg() {
  
  
  const arr=[{head:'Growth Marketing',desc:'Growth marketing takes a comprehensive approach, integrating various marketing channels and tactics to drive growth. Data lies at the heart of growth marketing. By leveraging data analytics and insights, we gain a deep understanding of our client’s target audience, identify key growth opportunities, and optimize marketing efforts for maximum impact.'},
  {head:'Paid Media Advertising',desc:'Paid media publicizing presents huge potential for organizations to arrive at their main interest group with accuracy and scale. Through tools like Google and Meta Advertisements, and display advertisements, we target explicit specific demographics, interests, and behaviors, driving traffic, leads, and conversions. With the capacity to track and gauge execution in real-time, paid media offers a cost-effective method for enhancing brand visibility.'},
  {head:'Brand Marketing',desc:'Brand Marketing is fundamental for organizations to lay out their personality, convey their qualities, values, and build trust with customers. Branding marketing aims to evoke emotions, establish authenticity, differentiate the brand from competitors, and foster long-term relationships with their audience by creating recognition and loyalty via giving consistent messages.'},
  {head:'Programmatic Ads',desc:'Programmatic Ads offer massive potential via automating the promotion and giving precise targeting. This precision ensures that ads are presented to users who are the most relevant, thereby increasing engagement and sales. Furthermore, automatic promotions empower sponsors to drive significant results continuously by focusing on, informing, and spending plans for ideal execution.'},
]
  // "Performance Marketing","Growth Marketing ","Branding Marketing","Programmatic"]

  
    return (
<Grid container justifyContent={'center'}>

    <Container style={{margin:'4vh 0px',textAlign:'-webkit-center'}}>
        <Grid  container style={{margin:'10px 0px'}}>
            <Grid item alignContent='center' sm={4} xs={12}>
<Typography className='bb_txt1'>
WHAT WE DO
</Typography>
<Typography className='bb_txt2'>
EXPERTS
</Typography>
<Typography className='bb_txt3'>
Let's talk performance marketing!
</Typography>
<img  style={{width:'30%'}} src={require('../../images/divider.png')} />
            </Grid>
            {arr.map((val,idx)=>
           <Grid key={idx}style={{padding:10}} item sm={4} xs={12}>
    
           <div  data-aos="flip-up"  data-aos-duration="1500" className='bb_services'>
           
           <Typography className='bb_services_head' >
 {val.head}
           </Typography>
           <Typography className='bb_services_desc' >
           {val.desc}           </Typography>
           
           </div>
           
           
                </Grid> )}
        </Grid>

    </Container>

    <Grid container style={{paddingTop:'5vh'}} sm={12} xs={12}>
       
        <div className='bg_quote'>

<Grid item sm={12} xs={12}>
<Typography className='bg_quote_txt1'>
BEST AFFILIATES EVER 
</Typography>
</Grid>
<Grid item sm={12} xs={12}>
<Typography className='bg_quote_txt2'>
Time to grow your affiliate network!
</Typography>
</Grid>
<Grid item textAlign={'center'} sm={12} xs={12}>
<Chip onClick={()=>window.location.href='/contact'} label='Get quote' style={{cursor:'pointer',background:'var(--color3)',color:'white',padding:'20px 10px',textTransform:'uppercase',fontWeight:'700',marginTop:'1vh'}} />
</Grid>
        </div>
    </Grid>
    </Grid>
  )
}
