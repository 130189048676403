import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './common/header/navbar';
import ScrollT from './common/STTP/scroll_Top';
import Footer from './common/footer/footer';
import Homepage from './homepage/homepage';
import TBI_PrivacyPolicy from './privacypolicy/TBI_PrivacyPolicy';
import TBI_Termsofuse from './privacypolicy/TBI_Terms';
import ContactUS from './contactus/contactus';
function App() {
  return (
    <BrowserRouter>
    <Navbar />
  <ScrollT />
  <Routes>
    <Route  path='/' element={<Homepage />}  />
    <Route  path='/privacy-policy' element={<TBI_PrivacyPolicy />}  />
    <Route  path='/terms-and-conditions' element={<TBI_Termsofuse />}  />
    <Route  path='/contact' element={<ContactUS />}  />

  </Routes>
<Footer />
    </BrowserRouter>
  );
}

export default App;
